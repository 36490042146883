import handleGET from './handleGET';
import handlePOST from './handlePOST';
import handlePUT from './handlePUT';
import handlePATCH from './handlePATCH';
import handleDELETE from './handleDELETE';
import { DateTime } from 'luxon';
import { navigate } from '@reach/router';

interface fetchAPIProps {
  method: RequestMethods;
  route: string;
  queryString?: string;
  id?: ID;
  data?: APIData;
  headers?: { timezone: string };
}

const fetchAPI = async <T>({
  method,
  route,
  id,
  queryString,
  data
}: fetchAPIProps): Promise<iResponse<T>> => {
  // Use the backend API URL base (e.g. https://localhost:9000) + the route

  const token = localStorage.getItem('token');

  const apiBase = process.env.REACT_APP_API_BASE;
  const url = `${apiBase}/${route}`;

  const zone: string[] = DateTime.now().toFormat('ZZ').split(':');
  const hour = Number(zone[0]);
  const mint = Number(zone[1]);
  const totalMint: number = hour * 60 + mint;

  // Construct fetch options using the getIdTokenResult Firebase value
  const fetchOptions: RequestInit = {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
      'Content-Type': 'application/json',
      timezone: `${totalMint}`
    },
    method
  };

  let response: iResponse<T>;
  const query = queryString;
  switch (method) {
    default:
    case `GET`:
      response = await handleGET({ fetchOptions, url, queryString: query, id });
      break;
    case `POST`:
      response = await handlePOST({ fetchOptions, url, queryString: query, data });
      break;
    case `PUT`:
      response = await handlePUT({ fetchOptions, url, queryString: query, data });
      break;
    case `PATCH`:
      response = await handlePATCH({ fetchOptions, url, queryString: query, id, data });
      break;
    case `DELETE`:
      response = await handleDELETE({ fetchOptions, url, queryString: query, id });
      break;
  }

  if (response.code === 403) {
    navigate('/error-403');
  }

  return response;
};

export default fetchAPI;
